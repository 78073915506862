import React, { useEffect, useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import logo from "../../images/header/logo.png"

import imgWdgDE from "../../images/header/wdg_de.svg";
import imgWdgFR from "../../images/header/wdg_fr.svg";
import imgWdgIT from "../../images/header/wdg_it.svg";

const Header = () => {
    const [resources, setResources] = useState({});

    let navigate = useNavigate();

    const { language } = useParams();

    // images
    let imgWdg = imgWdgDE;

    // changing languageId according to the url
    switch (language) {
        case ("fr" || "FR"):
            imgWdg = imgWdgFR;
            break;
        case ("it" || "IT"):
            imgWdg = imgWdgIT;
            break;
        default:
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    return (
        <>
            <div className="container wrapper mb-4" style={{ paddingTop: "30px" }}>
                <div className="d-flex align-items-center justify-content-between">
                    <a href="https://suissetec.ch/">
                        <img src={imgWdg} alt="" className="img-fluid logo" />
                    </a>

                    <a href="https://suissetec.ch/">
                        <img src={logo} alt="" className="img-fluid logo ms-auto me-3" />
                    </a>
                </div>
            </div>
        </>
    )
}

export default Header