import React from "react";

import "../../css/style.css";

const Loading = (props) => {
    return (
        <div className="d-flex justify-content-center mb-5">
            <div
                className="spinner spinner-border"
                role="status"
                style={{
                    color: props.color,
                    backgroundColor: props.bgColor,
                    top: "50%",
                    left: "50%",
                    width: "3rem",
                    height: "3rem",
                    position: "absolute",
                }}
            >
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

export default Loading;