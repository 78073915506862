import React, { useEffect, useState } from 'react'

import { TfiYoutube, TfiFacebook, TfiInstagram, TfiLinkedin } from "react-icons/tfi";
import { FaInstagram } from "react-icons/fa6";




import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

import "../../css/footer.css"

import imgLogo from "../../images/footer/suissetec_logo.svg";

import imgWdgDE from "../../images/footer/wdg_de.svg";
import imgWdgFR from "../../images/footer/wdg_fr.svg";
import imgWdgIT from "../../images/footer/wdg_it.svg";

import imgToplehrstellenDE from "../../images/footer/toplehrstellen_de.svg";
import imgToplehrstellenFR from "../../images/footer/toplehrstellen_fr.svg";
import imgToplehrstellenIT from "../../images/footer/toplehrstellen_it.svg";

const Footer = (props) => {
    const [resources, setResources] = useState({});

    let navigate = useNavigate();

    const { language } = useParams();

    // images
    let imgWdg = imgWdgDE;
    let imgTopLehrstellen = imgToplehrstellenDE;

    // changing languageId according to the url
    switch (language) {
        case ("fr" || "FR"):
            imgWdg = imgWdgFR;
            imgTopLehrstellen = imgToplehrstellenFR;
            break;
        case ("it" || "IT"):
            imgWdg = imgWdgIT;
            imgTopLehrstellen = imgToplehrstellenIT;
            break;
        default:
            break;
    }

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.footer);
            })
            .catch((error) => console.error(error.response.data));
    };

    let languageNavPath = props.pagePath;

    return (
        <>
            <div className="footer">
                <div className="container wrapper">
                    <div className="row">
                        <div className="footer-col-1">
                            <ul className="list-unstyled footer-list">
                                <li><a href={resources?.OnlineShopURL}>{resources?.Onlineshop}</a></li>
                                <li><a href={resources?.NewsURL}>{resources?.News}</a></li>
                                <li><a href={resources?.EventsURL}>{resources?.Events}</a></li>
                                <li><a href={resources?.NewsletterURL}>{resources?.Newsletter}</a></li>
                                <li><a href={resources?.PortalURL}>{resources?.Portal}</a></li>
                            </ul>
                        </div>
                        <div className="footer-col-2">
                            <ul className="list-unstyled footer-list">
                                <li><a href={resources?.PlanungURL}>{resources?.Planung}</a></li>
                                <li><a href={resources?.SanitarURL}>{resources?.Sanitar}</a></li>
                                <li><a href={resources?.HeizungURL}>{resources?.Heizung}</a></li>
                                <li><a href={resources?.SpenglerURL}>{resources?.Spengler}</a></li>
                                <li><a href={resources?.LuftungURL}>{resources?.Luftung}</a></li>
                            </ul>
                        </div>
                        <div className="footer-col-3">
                            <ul className="list-unstyled">
                                <li><a className="a-social-media" href="https://www.linkedin.com/company/suissetec"><span className="icon-social-media-div"><TfiLinkedin /></span>LinkedIn</a></li>
                                <li><a className="a-social-media" href="https://www.facebook.com/suissetecyoungprofessionals"><span className="icon-social-media-div"><TfiFacebook /></span>Facebook</a></li>
                                <li><a className="a-social-media" href="https://www.instagram.com/wirdiegebaeudetechniker"><span className="icon-social-media-div"><FaInstagram /></span>Instagram</a></li>
                                <li><a className="a-social-media" href="https://www.youtube.com/user/suissetectv"><span className="icon-social-media-div"><TfiYoutube /></span>YouTube</a></li>
                            </ul>
                        </div>
                        <div className="footer-col-4">
                            <p dangerouslySetInnerHTML={{ __html: resources?.Address }}></p>
                        </div>
                        <div className="footer-col-5">
                            <a href={resources?.URLWirDie} target="_blank" rel="noopener noreferrer" className="me-3">
                                <div className="d-inline-block"><img src={imgWdg} alt="" /></div>
                            </a>
                            <a href={resources?.URLTopLehrstellen} target="_blank" rel="noopener noreferrer">
                                <div className="d-inline-block"><img src={imgTopLehrstellen} alt="" /></div>
                            </a>

                            <div className="mt-5 text-end">
                                <select className="form-select select-language d-inline-block" value={language} aria-label="Language select"
                                    onChange={(e) => navigate(`/${e.target.value}/${languageNavPath}`)}>
                                    <option value="de">Deutsch</option>
                                    <option value="fr">Français</option>
                                    {/*<option value="it">Italiano</option>*/}
                                </select>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="d-flex align-items-center justify-content-between margin-top-30px">
                        <div>
                            <span className="me-2">© suissetec</span> |
                            <a href={resources?.AGBURL} className="ms-2 me-2">{resources?.AGB}</a> |
                            <a href={resources?.SupportURL} className="ms-2 me-2">{resources?.Support}</a> |
                            <a href={resources?.ContactURL} className="ms-2 me-2">{resources?.Contact}</a> |
                            <a href={resources?.ImpressumURL} className="ms-2 me-2">{resources?.Impressum}</a>
                        </div>
                        <img src={imgLogo} alt="" className="ms-auto me-3" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer