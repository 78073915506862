import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "ticketino-api-client";
import axios from "axios";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Loading from "./components/Loading";


const Home = () => {
    const [order, setOrder] = useState({});
    const [orderId, setOrderId] = useState();

    const [organizerId, setOrganizerId] = useState();
    const [posId, setPosId] = useState();

    const [selectedTicketType, setSelectedTicketType] = useState("");

    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(false);

    const [promotion, setPromotion] = useState({});
    const [promotioncode, setPromotioncode] = useState("");
    const [promotioncodeAdded, setPromotioncodeAdded] = useState("");
    const [promotionError, setPromotionError] = useState("");

    const [unlockedTicketTypes, setUnlockedTicketTypes] = useState([]);
    
    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    const ticketTypeMember = 782914;
    const ticketTypeNichtMitglied = 782913;
    const ticketTypeNichtMitgliedPromo = 782916;
    const ticketTypeNichtMitgliedGuest = 782917;
    const ticketTypeDiplomander = 782915;
    const ticketTypeAussteller = 782912;

    const ticketTypes = [
        {
            id: 1, ticketTypeIds: [ticketTypeMember],
            name: "Mitglied", content: resources?.HomeTicketTypeAMitglied
        },
        {
            id: 2, ticketTypeIds: [ticketTypeNichtMitgliedPromo, ticketTypeNichtMitgliedGuest],
            name: "NichtMitglied", content: resources?.HomeTicketTypeBNichtMitglied
        },
        {
            id: 3, ticketTypeIds: [ticketTypeDiplomander],
            name: "Diplomander", content: resources?.HomeTicketTypeCDiplomander
        },
        {
            id: 4, ticketTypeIds: [ticketTypeAussteller],
            name: "Aussteller", content: resources?.HomeTicketTypeDAussteller
        },
    ]

    useEffect(() => {

        setLoading(true);
        const loadToken = async () => {
            try {
                const res = await axios.get("form/token");
                axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
                api.defaults.headers.common['Authorization'] = "Bearer " + res.data;
                await sessionStorage.setItem("token", res.data);

                sessionStorage.removeItem("addedPromotion"); // clear promotion code
                sessionStorage.removeItem("quantityOfBookableTickets");
                sessionStorage.removeItem("selectedTicketTypeId");
                requestFormSettings();
            } catch (error) {
                console.error(error);
            }
        };

        let currentOrderId = sessionStorage.getItem("orderId");

        if (currentOrderId > 0) {
            setOrderId(currentOrderId);
        }

        const requestFormSettings = async () => {
            try {
                const res = await axios.get(`form/formsettings`);

                if (currentOrderId > 0) {
                    loadOrder(currentOrderId)
                } else {
                    startOrder(res.data.posId);
                }

                setPosId(res.data.posId)
                setOrganizerId(res.data.organizerId)
            } catch (error) {
                console.error(error);
            }
        };

        loadToken();
        setLoading(false);

    }, []);

    const loadOrder = async (orderId) => {
        try {
            // Order
            const updatedOrder = await getOrderByOrderId(orderId);

            if (updatedOrder != null && updatedOrder.tickets != null) {

                let ticketTypeIdsToRemove = updatedOrder.tickets
                    .map(ticket => ticket.id);

                // DELETE request to remove tickets
                await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                    data: { TicketsToRemove: ticketTypeIdsToRemove }
                });

                updatedOrder.tickets = [];
            }

            setOrder(updatedOrder);
        }
        catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        requestResources();
        
    }, [language]);

    useEffect(() => {
        if (unlockedTicketTypes.length > 0) {
            addTicketToBasket(unlockedTicketTypes)
        }
    }, [unlockedTicketTypes]);


    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
            if (error.response.status === 404 || error.response.status === 403) {
                const newOrder = await startOrder(posId);
                return newOrder;
            }
        }
    }

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const startOrder = async (posId) => {

        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            languageId: languageId,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 1,
        };

        await axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("orderId", res.data.id);
                setOrderId(res.data.id);
                setLoaded(true);
            })
            .catch((error) => console.error(error.response.data));
    };


    const onSubmit = async () => {    
        if (selectedTicketType === "Diplomander") {
            //diplomander
            await addTicketToBasket();
        } else if (selectedTicketType === "NichtMitglied") {
            //nicht mitglied
            if (promotioncode === "") {
                await addTicketToBasket();
            } else {
                await addPromotionToOrder();
            }
        } else if (selectedTicketType === "Mitglied" || selectedTicketType === "Aussteller") {
            //mitglid, aussteller
            if (promotioncode !== "") {
                await addPromotionToOrder();
            } else {
                setPromotionError(selectedTicketType === "Mitglied" ? resources?.PromotionErrorRequiredMitglied : resources?.PromotionErrorRequired)
            }
        }
    }

    const addPromotionToOrder = async () => {
        setPromotionError("");
        setLoading(true);

        let promotioncodeToAdd = promotioncode;
        if (selectedTicketType === "Mitglied") promotioncodeToAdd = "memberHLK2025" + promotioncode;

        const updatedUnlockedTicketTypes = await applyPromotionCodeToOrder(orderId, promotioncodeToAdd);

        if (updatedUnlockedTicketTypes) { 
            const promotion = await getPromotionByPromotionCode(promotioncodeToAdd, organizerId);

            if (promotion) {
                setPromotion(promotion);
            }
        }
        
        
        sessionStorage.setItem("addedPromotion", promotioncodeToAdd);
        setPromotioncodeAdded(promotioncodeToAdd);
        setUnlockedTicketTypes(updatedUnlockedTicketTypes?.unlockedTicketTypes ?? []);
;
        setLoading(false);
    }

    const applyPromotionCodeToOrder = async (orderId, code) => {
        try {
            const response = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(resources?.PromotionError);
        }
    }

    const getPromotionByPromotionCode = async (code, organizerId) => {
        try {
            const response = await axios.get(`${baseUrl}/PromotionCode/${code}/Promotion?organizerId=${organizerId}`);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(resources?.PromotionError);
        }
    }

    const addTicketToBasket = async (unlockedTicketTypes) => {
        let addTicketTypes = [];

        let ticketTypeId = 0;

        // Has promocode
        if (unlockedTicketTypes && unlockedTicketTypes.length > 0) {

            // Find the ticket type object by name
            const ticketType = ticketTypes.find((type) => type.name === selectedTicketType);

            if (ticketType && unlockedTicketTypes.some((id) => ticketType.ticketTypeIds.includes(id))) {

                const matchingTicketTypes = unlockedTicketTypes.filter((id) => ticketType.ticketTypeIds.includes(id));

                ticketTypeId = matchingTicketTypes.length > 0 ? matchingTicketTypes[0] : 0;//Get the first matching ticket type
            } 
        } else {
            if (selectedTicketType === "Diplomander") {
                ticketTypeId = ticketTypeDiplomander; //Diplomander ticket type
            } else {
                ticketTypeId = ticketTypeNichtMitglied; //Nicht Mitglieder ticket type
            }
        }

        if (ticketTypeId === 0) {
            return;
        }

        //check quantity of tickets that can be booked
        if (promotion && promotion.maxDiscountedTicketsPerOrder) {
            sessionStorage.setItem("quantityOfBookableTickets", promotion.maxDiscountedTicketsPerOrder);
        } else {
            sessionStorage.setItem("quantityOfBookableTickets", "4");
        }

        sessionStorage.setItem("selectedTicketType", selectedTicketType);
        sessionStorage.setItem("selectedTicketTypeId", ticketTypeId);

        navigate(`/${language}/address`);
    };

    return (
        <>
            <div className="page-container">
                <Header language={language} />
                <div className="container wrapper-small">

                    <div className="row mb-4">
                        <div className="col-md-12 text-center title">
                            {resources?.HeaderTitle1} <br />
                            {resources?.HeaderTitle2}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center subtitle">
                            {resources?.HomeDescription}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-md-12 text-center subtitle fw-bold">
                            {resources?.HomeDateLocal}
                        </div>
                    </div>
                    <div className="mt-4">
                        <p className="fs-5 fw-bold mb-2">{resources?.HomeIam}</p>
                    </div>
                    <div className='categories mb-3'>
                        {ticketTypes.map((ticketType, index) => (
                            <div className='category row' onClick={() => setSelectedTicketType(ticketType.name)} key={index}>
                                <div className="col-1 fs-5 text-secondary"><i className={`bi bi${(selectedTicketType == ticketType.name) ? "-check" : ""}-square`}></i></div>
                                <div className="col-10">
                                    {ticketType.content}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div>
                        <p className="mb-2">{resources?.HomeTicketControl}</p>
                    </div>
                    <div className="mt-5">
                        <p className="fs-5 fw-bold mb-2">{resources?.PromotionCode}</p>
                        <p className="fs-6" dangerouslySetInnerHTML={{ __html: resources?.PromotionDescription }}></p>
                        <div className="row">
                            <div className="col-md-4 col-8 mb-3">
                                <input className="form-control" value={promotioncode} onChange={(e) => setPromotioncode(e.target.value)} />
                                {promotionError !== "" &&
                                    <span className="error">{promotionError}</span>
                                }
                            </div>
                            <div className="col-md-2 col-4 mb-3 row text-end pe-0">
                                <div className="col"><button className="btn button-custom col-12" disabled={selectedTicketType === ""} onClick={onSubmit}>{resources?.Next}</button></div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4 mb-4">
                        <a className="btn button-custom" href="https://suissetec.ch/">
                            {resources?.Back}
                        </a>
                    </div>
                </div>

                <Footer language={language} pagePath="home" />
            </div>
        </>
    )
}

export default Home