import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getAddressOfOrder } from "ticketino-api-client";

import Header from './components/Header'
import Footer from "./components/Footer";
import Loading from "./components/Loading";

const Address = () => {
    const [order, setOrder] = useState({});
    const [orderId, setOrderId] = useState();
    const [organizerId, setOrganizerId] = useState(0);
    const [promotioncode] = useState(sessionStorage.getItem("addedPromotion"));
    const [quantityOfBookableTickets] = useState(sessionStorage.getItem("quantityOfBookableTickets"));
    const [selectedTicketTypeId] = useState(sessionStorage.getItem("selectedTicketTypeId"));

    const [loading, setLoading] = useState(false);

    const [buyerInfo, setBuyerInfo] = useState({
        salutation: "",
        firstName: "",
        name: "",
        company: "",
        email: "",
        street: "",
        houseNumber: "",
        telephone: "",
        mobile: "",
        city: "",
        postCode: "",
        countryId: 176,
        email: "",
        confirmemail: "",
        companion1firstname: "",
        companion1lastname: "",
        companion2firstname: "",
        companion2lastname: "",
        companion3firstname: "",
        companion3lastname: "",
    });

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // setting the base url of the npm package api calls
    api.defaults.baseURL = baseUrl;

    // fetching resources
    const [resources, setResources] = useState({});
    const [isAgb1Checked, setIsAgb1Checked] = useState(false);
    const [isAgb2Checked, setIsAgb2Checked] = useState(false);
    const [isAgb3Checked, setIsAgb3Checked] = useState(false);

    // fetching params
    const { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 0;
            break;
    }

    useEffect(() => {
        const loadToken = async () => {
            try {
                const res = await axios.get("form/token");
                axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
                api.defaults.headers.common['Authorization'] = "Bearer " + res.data;
                await sessionStorage.setItem("token", res.data);

                requestFormSettings();
            } catch (error) {
                console.error(error);
            }
        };

        let currentOrderId = sessionStorage.getItem("orderId");

        if (currentOrderId > 0) {
            setOrderId(currentOrderId);
        }


        const requestFormSettings = async () => {
            try {
                const res = await axios.get(`form/formsettings`);

                if (currentOrderId > 0) {
                    loadOrder(currentOrderId)
                } else {
                    sessionStorage.removeItem("addedPromotion"); // clear promotion code
                    navigate(`/${language}/home`);
                }

                setOrganizerId(res.data.organizerId)
            } catch (error) {
                console.error(error);
            }
        };

        loadToken();
    }, []);

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed


    useEffect(() => {
        if (promotioncode && promotioncode != "" && organizerId && organizerId !== 0) {
            loadAddress(promotioncode);
        }
    }, [promotioncode, organizerId]);

    const requestResources = async () => {
        await axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.translation);
            })
            .catch((error) => console.error(error.response.data));
    };

    const removePromotionCode = async (promotion) => {
        try {
            const response = axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${promotion}`);
            return response.data;
        } catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
            if (error.response.status === 404 || error.response.status === 403) {
                navigate(`/${language}/home`);
            }
        }
    }

    const loadOrder = async (orderId) => {
        try {
            // Order
            const updatedOrder = await getOrderByOrderId(orderId);

            if (updatedOrder != null && updatedOrder.tickets != null) {

                let ticketTypeIdsToRemove = updatedOrder.tickets
                    .map(ticket => ticket.id);

                // DELETE request to remove tickets
                await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                    data: { TicketsToRemove: ticketTypeIdsToRemove }
                });
            }

            setOrder(updatedOrder);
        }
        catch (error) {
            console.error(error);
        }
    }

    const loadAddress = async (code) => {
        try {
            let updatedAddress = {};

            try {
                updatedAddress = await getAddressOfPromotionCode(code, organizerId);
            } catch { }

            if (updatedAddress) {
                setBuyerInfo((prevBuyerInfo) => ({
                    ...prevBuyerInfo,
                    salutation: updatedAddress.salutation,
                    firstName: updatedAddress.firstName,
                    name: updatedAddress.name,
                    company: updatedAddress.company,
                    email: updatedAddress.email,
                    telephone: updatedAddress.telephone,
                    mobile: updatedAddress.mobile,
                    street: updatedAddress.street,
                    city: updatedAddress.city,
                    postCode: updatedAddress.postCode,
                }));
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getAddressOfPromotionCode = async (promotionCode, organizerId) => {
        try {
            const res = await axios.get(`${baseUrl}/PromotionCode/${promotionCode}/Address?organizerId=${organizerId}`);
            return res.data;
        } catch (error) {
            console.error(error);
        }
    }

    const onInputChange = (e) => {
        setBuyerInfo({ ...buyerInfo, [e.target.name]: e.target.value });
    };

    const onAgb1Change = (isAgb1Checked) => {
        setIsAgb1Checked(isAgb1Checked);
    };

    const onAgb2Change = (isAgb2Checked) => {
        setIsAgb2Checked(isAgb2Checked);
    };

    const onAgb3Change = (isAgb3Checked) => {
        setIsAgb3Checked(isAgb3Checked);
    };

    const onSubmit = async () => {

        setLoading(true);

        await addAddressToBasket(buyerInfo.companion1firstname, buyerInfo.companion1lasttname);
    }

    const addAddressToBasket = async () => {
        let addressBody = { ...buyerInfo };

        await axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .then(() => {
                sessionStorage.setItem("FassadenOrderAddress", JSON.stringify(addressBody));

                // gtm - addAddress
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'addAddress'
                });

                addTicketsToBasket();
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const addTicketsToBasket = async (firstname, lastname) => {

        const addTicketTypes = [];

        //buyer
        addTicketTypes.push({
            firstname: buyerInfo.firstName,
            lastname: buyerInfo.name,
            company: buyerInfo.company,
            street: buyerInfo.street,
            postCode: buyerInfo.postCode,
            city: buyerInfo.city,
            phone: buyerInfo.telephone,
            mobile: buyerInfo.mobile,
            email: buyerInfo.email,
            promotionCode: promotioncode,
            ticketTypeId: selectedTicketTypeId,
            quantity: 1,
        });

        if (buyerInfo.companion1firstname !== "" && buyerInfo.companion1lastname !== "") {
            //companion 1
            addTicketTypes.push({
                firstname: buyerInfo.companion1firstname,
                lastname: buyerInfo.companion1lastname,
                promotionCode: promotioncode,
                ticketTypeId: selectedTicketTypeId,
                quantity: 1,
            });
        }

        if (buyerInfo.companion2firstname !== "" && buyerInfo.companion2lastname !== "") {
            //companion 2
            addTicketTypes.push({
                firstname: buyerInfo.companion2firstname,
                lastname: buyerInfo.companion2lastname,
                promotionCode: promotioncode,
                ticketTypeId: selectedTicketTypeId,
                quantity: 1,
            });
        }

        if (buyerInfo.companion3firstname !== "" && buyerInfo.companion3lastname !== "") {
            //companion 3
            addTicketTypes.push({
                firstname: buyerInfo.companion3firstname,
                lastname: buyerInfo.companion3lastname,
                promotionCode: promotioncode,
                ticketTypeId: selectedTicketTypeId,
                quantity: 1,
            });
        }

        await axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(() => {
                // gtm - addToCart
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'addToCart'
                });

                navigate(`/${language}/checkout`)
            })
            .catch((error) => {
                console.error(error.response.data);
                navigate(`/${language}/home`)
            });
    };

    let validation =
        buyerInfo.salutation && /^.{1,70}$/.test(buyerInfo.salutation) &&
        buyerInfo.firstName && /^.{1,70}$/.test(buyerInfo.firstName) &&
        buyerInfo.name && /^.{1,70}$/.test(buyerInfo.name) &&
        buyerInfo.company && /^.{1,70}$/.test(buyerInfo.company) &&
        buyerInfo.street && /^.{1,70}$/.test(buyerInfo.street) &&
        buyerInfo.postCode && /^.{1,70}$/.test(buyerInfo.postCode) &&
        buyerInfo.city && /^.{1,70}$/.test(buyerInfo.city) &&
        buyerInfo.email && /^\S+@\S+\.\S+$/.test(buyerInfo.email) &&
        buyerInfo.confirmemail && /^\S+@\S+\.\S+$/.test(buyerInfo.confirmemail) &&
        buyerInfo.email === buyerInfo.confirmemail;

    if (!isAgb1Checked) validation = false;
    if (!isAgb2Checked) validation = false;
    if (!isAgb3Checked) validation = false;

    const onBackClick = async () => {
        const promotioncode = sessionStorage.getItem("addedPromotion");

        if (promotioncode?.length > 0) {
            sessionStorage.removeItem("addedPromotion"); // clear promotion code
            await removePromotionCode(promotioncode);
        }

        navigate(-1)
    }

    const companions = () => {
        return (
            <>
                {/* Companion 1 */}
                {quantityOfBookableTickets > 1 &&
                    <>
                        <div className="mb-3 mt-5 row">
                            <div className="col-md-12 fw-bold">
                                {resources?.BuyerCompanion} 1:
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <div className="col-6">
                                <input
                                    type="text"
                                    name="companion1firstname"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerFirstname}
                                    value={buyerInfo.companion1firstname}
                                    id="companion1firstname"
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className="col-6">
                                <input
                                    type="text"
                                    name="companion1lastname"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerLastname}
                                    value={buyerInfo.companion1lastname}
                                    id="companion1lastname"
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                    </>
                }

                {/* Companion 2 */}
                {quantityOfBookableTickets > 2 &&
                    <>
                        <div className="mb-3 mt-4 row">
                            <div className="col-md-12 fw-bold">
                                {resources?.BuyerCompanion} 2:
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <div className="col-6">
                                <input
                                    type="text"
                                    name="companion2firstname"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerFirstname}
                                    value={buyerInfo.companion2firstname}
                                    id="companion2firstname"
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className="col-6">
                                <input
                                    type="text"
                                    name="companion2lastname"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerLastname}
                                    value={buyerInfo.companion2lastname}
                                    id="companion2lastname"
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                    </>
                }

                {/* Companion 3 */}
                {quantityOfBookableTickets > 3 &&
                    <>
                        <div className="mb-3 mt-4 row">
                            <div className="col-md-12 fw-bold">
                                {resources?.BuyerCompanion} 3:
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <div className="col-6">
                                <input
                                    type="text"
                                    name="companion3firstname"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerFirstname}
                                    value={buyerInfo.companion3firstname}
                                    id="companion3firstname"
                                    onChange={onInputChange}
                                />
                            </div>
                            <div className="col-6">
                                <input
                                    type="text"
                                    name="companion3lastname"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerLastname}
                                    value={buyerInfo.companion3lastname}
                                    id="companion3lastname"
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }

    return (
        <div className="page-container">
            <Header />
            {loading ? (
                <>
                    <Loading
                        alignment="center"
                        color="#d3d3d3"
                        bgColor="#fff"
                        top="50%"
                    />

                </>
            ) : (
                <>
                    <div className="container wrapper-small">
                        <div className="row mb-4">
                            <div className="col-md-12 text-center title">
                                {resources?.HeaderTitle1} <br />
                                {resources?.HeaderTitle2}
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12 text-center">
                                {resources?.BuyerPleaseFill}
                            </div>
                        </div>
                        <div className="mb-3 mt-4 row">
                            <div className="col-12">
                                <select className={`form-control input-field ${!buyerInfo.salutation ? "placeholder-option" : ""}`} value={buyerInfo.salutation} name="salutation" onChange={onInputChange}>
                                    <option value="" className="placeholder-option">{resources?.BuyerSalutation}*</option>
                                    <option value={resources?.BuyerSalutationMr}>{resources?.BuyerSalutationMr}</option>
                                    <option value={resources?.BuyerSalutationMs}>{resources?.BuyerSalutationMs}</option>
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    name="firstName"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerFirstname + "*"}
                                    value={buyerInfo.firstName}
                                    id="firstName"
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    name="name"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerLastname + "*"}
                                    value={buyerInfo.name}
                                    id="name"
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    name="company"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerCompany + "*"}
                                    value={buyerInfo.company}
                                    id="company"
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    name="street"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerStreet + "*"}
                                    value={buyerInfo.street}
                                    id="street"
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    name="postCode"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerPostcode + "*"}
                                    value={buyerInfo.postCode}
                                    id="postCode"
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    name="city"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerCity + "*"}
                                    value={buyerInfo.city}
                                    id="city"
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    name="telephone"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerNumber}
                                    value={buyerInfo.telephone}
                                    id="telephone"
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    name="mobile"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerMobile}
                                    value={buyerInfo.mobile}
                                    id="mobile"
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    name="email"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerEmail + "*"}
                                    value={buyerInfo.email}
                                    id="email"
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-12">
                                <input
                                    type="text"
                                    name="confirmemail"
                                    className="form-control input-field"
                                    placeholder={resources?.BuyerConfirmEmail + "*"}
                                    value={buyerInfo.confirmemail}
                                    id="confirmemail"
                                    onChange={onInputChange}
                                />
                            </div>
                        </div>

                        {quantityOfBookableTickets &&
                            companions()
                        }

                        {/* AGB 1 */}
                        <div className="row mt-5" onClick={() => onAgb1Change(!isAgb1Checked)}>
                            <div className="col-1 ps-md-4 fs-5 text-secondary">
                                <i className={`bi bi${(isAgb1Checked) ? "-check" : ""}-square`}></i>
                            </div>
                            <div className="col-11 ps-md-0">
                                <label style={{ marginTop: "2px" }}>{resources?.BuyerAGB1}</label>
                            </div>
                        </div>
                        {/* AGB 2 */}
                        <div className="row mt-4" onClick={() => onAgb2Change(!isAgb2Checked)}>
                            <div className="col-1 ps-md-4 fs-5 text-secondary">
                                <i className={`bi bi${(isAgb2Checked) ? "-check" : ""}-square`}></i>
                            </div>
                            <div className="col-11 ps-md-0">
                                <label style={{ marginTop: "2px" }}>{resources?.BuyerAGB2}</label>
                            </div>
                        </div>
                        {/* AGB 3 */}
                        <div className="row mt-4" onClick={() => onAgb3Change(!isAgb3Checked)}>
                            <div className="col-1 ps-md-4 fs-5 text-secondary">
                                <i className={`bi bi${(isAgb3Checked) ? "-check" : ""}-square`}></i>
                            </div>
                            <div className="col-11 ps-md-0">
                                <label style={{ marginTop: "2px" }} dangerouslySetInnerHTML={{ __html: resources?.BuyerAGB3 }}></label>
                            </div>
                        </div>
                        <div className="row mt-5 mb-4">
                            <div className=" col-6 text-start">
                                <button className="btn button-custom" onClick={onBackClick}>
                                    {resources?.Back}
                                </button>
                            </div>
                            <div className="col-6 text-end">
                                <button className="btn button-custom" disabled={!validation} onClick={onSubmit}>{resources.Next}</button>
                            </div>
                        </div>
                    </div>
                    <Footer language={language} pagePath="address" />
                </>
            )};
        </div>
    )
}

export default Address